<template>
  <div class=" card left" v-if="this.incomingParties.length"
       :style="{maxWidth: this.width}"
       style="padding: 5px;background-color: black;color: #61EAC8 !important;right: 0;border-left: 2px aqua solid">
    <div class="input-group-prepend">
      <VMenu :triggers="['click']"
             :delay="2"
             :popperHideTriggers="['click']"
             :hideTriggers="['click']">
        <span
          class="tim-icons icon-light-3 card-animation-on-hover" style="font-size: 35px;margin: 5px;padding: 5px" >
        </span>

        <template #popper>
          <div class="card card-bg-black text-left">
            <h5 class="tulum-party-subtitle-bg">Upcoming Events</h5>
            <mini-event-card :event="row" v-for="(row, idx) in  incomingParties.slice(0,5)" :key="idx"></mini-event-card>
            <h5 class="tulum-party-subtitle-bg">Resources</h5>

            <span
              class="btn btn-secondary btn-sm text-left"
              @click="goToParty('calendar')">
               Full Event Calendar </span>
            <span
              class="btn btn-secondary btn-sm text-left"

              @click="goToParty('clubs')">

            >
            Top Clubs</span>
          </div>
        </template>
      </VMenu>

    </div>
  </div>
</template>
<script>
import MiniEventCard from "@/components/EventCard/MiniEventCard";
export default {
  name: 'EventSearch',
  components: {MiniEventCard},
  data() {
    return {
      width: '90px',
      showSearch: false
    }
  },
  props: {
    incomingParties: {
      type: Array, // striped | hover
      default: [],
    },
  },
  computed: {

  },
  methods: {
    show() {
      this.showSearch = !this.showSearch;
      this.width = this.showSearch ? '300px' : '90px';
    },
    goToParty(event) {
      this.$rollbar.info('QUICK PARTY: ' + event);
      if(event === 'calendar') {
        this.$router.push({path: '/tulum-party-mexico-event-calendar', replace: true})
        return;
      }
      if (event === 'zamna') {
        this.$router.push({path: '/brands/zamna-tulum', replace: true})
        return;
      }
      if (event === 'clubs') {
        this.$router.push({path: '/tulum-clubs', replace: true})
        return;
      }
      this.$router.push({ path: '/event/'+ event, replace: true })
    },
  },
};
</script>
