<template>
  <article
    class="content"
    style="margin-top: -50px;padding:10px;background-color: #221f1f"
    itemscope
    itemtype="http://schema.org/Event"
    v-if="!this.$store.state.status.loading"
  >
    <div class="header text-center" style="margin-bottom: 5px">
      <br/>
      <br/>
      <h1
        class="card-title tulum-party-title mt-0 mb-0"
        style="
          font-size: 1.9rem;
          font-weight: bold;
          text-transform: uppercase;
          color: #fff !important;
        "
        itemprop="name"
      >
        {{ currentEvent.title }}
      </h1>
      <p class="event-time-location">
        <time
          itemprop="startDate"
          v-bind:content="currentEventDateNTime"
          v-bind:datetime="currentEventDateNTime"
          v-if="currentEventDateNTime"
        >
          {{ formatDate(currentEventDateNTime) }}
        </time>
        <span>{{ currentEventPlace }}</span>
        <span>
          , <span>{{ currentEventLocality }}</span>
        </span>
      </p>
      <br/>

      <p
        style="
          text-transform: uppercase;
          font-weight: bolder;
          color: #ffffff !important;
        "
        v-if="currentEvent.metadata.caption"
      >
        {{ currentEvent.metadata.caption }}
      </p>
      <br/>

      <img
        v-if="!this.enlargeImg "
        width="350px"
        height="350px"
        @click="this.enlargeImage"
        itemprop="image"
        v-bind:content="currentEventImage+'?w=350&h=350&fit=clamp&auto=format'"
        v-lazy="currentEventImage+'?w=350&h=350&fit=clamp&auto=format'"
        style="box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%)"
        v-bind:alt="currentEvent.title"
      />
      <img
        v-if="this.enlargeImg"
        itemprop="image"
        @click="this.enlargeImage"
        class="image-on-show"
        v-bind:content="currentEventImage"
        v-lazy="currentEventImage"
        style="box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%)"
        v-bind:alt="currentEvent.title"
      />
      <br v-if="this.enlargeImg"/>
      <br/>
      <span
        style="
          font-weight: bold;
          vertical-align: top;
          font-size: 0.9rem;
          margin-bottom: 15px;
          color: #ffffff;
          background: black;
          min-width: 40px;
          text-transform: uppercase;
        "
        v-if="!this.enlargeImg"
        @click="this.enlargeImage"
        class="tim-icons icon-zoom-split"
      >+</span
      >

      <EventBrand :title="currentEvent.title"></EventBrand>
      <br>
      <EventVenue :title="currentEvent.title"></EventVenue>

      <br>

      <div v-if="isPastEvent" style="margin-top: 50px">

        <h4  class="tulum-party-subtitle " style="font-size: 0.9rem">
          ⚠️ This event occurred in the past
        </h4>
        <p class="text-center">
          Check our
          <router-link
            :to="{ name: 'Home' }">Event Line</router-link>
         or  <router-link :to="{ name: 'PartyCalendar' }"> Calendar </router-link>for the full list of <b>upcoming events</b></p>
        <div class="text-center btn-group btn-group-vertical"
             style="
          margin: 10px;
          margin-top: 30px;
          width: 100%;
          max-width: 350px;
          background-color: #2f2c2c;
        "
        >

          <router-link
            v-if="isPastEvent"
            :to="{ name: 'Home' }"
            class="btn btn-primary">
            upcoming events
          </router-link>

        </div>
      </div>


      <div
        class="text-center btn-group btn-group-vertical"
        style="
          margin: 10px;
          margin-top: 20px;
          margin-bottom: 40px;
          width: 100%;
          max-width: 350px;
          background-color: #2f2c2c;
        "
      >
        <a
          v-if="currentEvent.metadata.tickets_link && !isPastEvent"
          v-bind:href="currentEvent.metadata.tickets_link"
          v-on:click="onTicketClick(currentEvent.title)"
          class="btn btn-primary"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          GET TICKETS
        </a>
      </div>
      <div
        class="card"
        style="padding: 10px;max-width: 500px;margin-left: auto;margin-right: auto;
          background-color: rgb(26, 24, 24);margin-top: -20px; "
        v-if="!currentEvent.metadata.tickets_link && !isPastEvent"
      >
        <h3 class="tulum-party-subtitle" style="margin-top: 20px">📲 INFO & RSVP</h3>
        <EventVenue :title="currentEvent.title" no-icon style="margin-top: -30px"></EventVenue>

        <p style="margin-top: 20px">
          <strong>Secure your spot.</strong>
          <br>Have questions or need more details? We're here to help.
        </p>
        <Reservations identifier="GeneralQuestion"></Reservations>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h3
          class="tulum-party-subtitle brand-title-black"
          style="text-transform: uppercase; font-size: 1rem"
        >
          Event Details
        </h3>
        <hr/>
        <div itemprop="description">
          <div v-html="currentEvent.content" class="card-text"></div>
        </div>
        <div v-if="currentEvent.metadata.extra_content">
          <h3 class="tulum-party-subtitle brand-title-black">Extras</h3>
          <hr/>
          <div
            v-html="currentEvent.metadata.extra_content"
            v-if="currentEvent.metadata.extra_content"
            class="card-text"
          ></div>
        </div>
        <a
          v-bind:href="currentEvent.metadata.event_link"
          class="btn btn-primary"
          v-if="currentEvent.metadata.event_link"
          onclick="window.ga('send', 'event', 'Event', 'Site', 'event_link' ,  1)"
        >
          EVENT SITE
        </a>

        <div style="margin-top: 5px" v-if="currentEvent.metadata.venue">
          <hr/>
          <h3 class="tulum-party-subtitle brand-title-black">LOCATION</h3>

          <router-link
            :to="{
              name: 'Club',
              params: { slug: currentEvent.metadata.venue.slug },
            }"
            class="btn-link btn-sm"
            style="font-size: 0.8rem"
          >
            📍 {{ currentEvent.metadata.venue.title.toUpperCase() }}
          </router-link>

          <hr/>
          <span
            itemprop="location"
            itemscope=""
            itemtype="http://schema.org/Place"
          >
            <span itemprop="name">{{ currentEventPlace }}</span>
            <br/>
            <span
              id="address-microdata"
              style="font-size: 10px"
              itemprop="address"
              itemscope=""
              itemtype="http://schema.org/PostalAddress"
            >
              {{ currentEventAddress }} ,
              <span itemprop="addressLocality">{{ currentEventLocality }}</span>
              <meta
                itemref="address-microdata"
                itemprop="addressCountry"
                content="MX"
              />
            </span>
          </span>
          <br/>
          <br/>
        </div>
        <!--        <VIDEO-->
        <div class="text-center card" style="border: 1px solid black"
             v-if="currentEvent.metadata.video && currentEvent.metadata.video.metadata"
        >
          <hr/>
          <HlsVideoPlayer
            v-bind:poster="
              currentEvent.metadata.video.metadata.previewimage.imgix_url
            "
            v-bind:content="currentEvent.metadata.video.content"
            v-bind:video-url="
              currentEvent.metadata.video.metadata.video.imgix_url.replace(
                'https://cdn.cosmicjs.com/',
                'https://imgix.cosmicjs.com/'
              )
            "
          >
          </HlsVideoPlayer>
          <!--        <PHOTOS-->
        </div>
      </div>

      <div
        class="text-center "
        style="margin-top: 30px; margin-bottom: 40px"
        v-if="currentEvent.metadata.tickets_link"
      >
        <a
          style="width: 80%"
          v-if="!isPastEvent"
          v-bind:href="currentEvent.metadata.tickets_link"
          v-on:click="onTicketClick(currentEvent.title)"
          rel="nofollow noopener noreferrer"
          target="_blank"
          class="btn btn-primary centered text-center"
        >
          TICKETS
        </a>
        <p v-if="isPastEvent">
          This event occurred in the past, see the list of the upcoming events
        </p>

        <router-link
          v-if="isPastEvent"
          :to="{ name: 'Home' }"
          class="btn btn-primary"
        >
          upcoming events
        </router-link>
      </div>
      <div
        class="card text-center"
        style="
          display: flex;
          justify-content: center;
          padding: 1px;
          margin-top: 30px;
          margin-bottom: 0;
        "
        v-if="currentEvent.metadata.reservations_link && !isPastEvent"
      >

        <EventReservations :slug="currentEvent.slug"></EventReservations>
        <br>
        <br>
        <EventSearch :incoming-parties="this.incomingParties"
                     style="position: fixed;bottom: -10px;z-index: 10" v-if="this.partiesLoaded"></EventSearch>
      </div>
    </div>
  </article>
</template>

<script>
import Reservations from '../../components/Reservations/Reservations';
import HlsVideoPlayer from '@/components/HlsVideoPlayer/HlsVideoPlayer';
import moment from 'moment';
import {mapState} from 'vuex';
import EventSearch from "@/components/EventSearch";
import EventBrand from "@/components/EventCard/EventBrand";
import EventVenue from "@/components/EventCard/EventVenue";
import EventReservations from "@/components/EventCard/EventReservations";
import {venueDecoder} from "@/common/Decoder";

export default {
  name: 'Event',
  props: ['slug'],
  metaInfo() {
    const eventKeywords = this.slug.replace(/-/g, ',').toUpperCase();
    // const eventImage = this.currentEventImage ? [{rel: 'preload', as: 'image', href: this.currentEventImage + '?w=240&auto=format'}] : [];
    return {
      title: `${
        this.currentEvent.title ? this.currentEvent.title : 'Loading ...'
      }` + ' - TULUM PARTY',
      // link: eventImage,
      meta: [
        {
          name: 'description',
          content: `Event Info, Tickets & Reservations. ${this.currentEventCaption}. ${this.cleanContent}`,
        },
        {
          name: 'keywords',
          content: `Tulum, tickets, party, information, reservations, bookings, ${eventKeywords}, club, nightclub`,
        },
        {
          property: 'og:title',
          content: `${this.currentEvent.title}`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/event/${this.slug}`,
        },
        {
          property: 'og:site_name',
          content: `TULUM PARTY`,
        },
        {
          property: 'og:description',
          content: `Event Tickets & Reservations. ${this.currentEventCaption}`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.currentEventImage,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('getParty', {slug: this.slug});
  },
  data: () => {
    return {
      table: [],
      enlargeImg: false,
      showReservations: false,
    };
  },
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd';
      return moment(partyDate).calendar({format: format});
    },
  },
  components: {EventReservations, EventBrand, EventSearch, Reservations, HlsVideoPlayer, EventVenue},
  methods: {
    onTicketClick(event) {
      let value = 2;
      if (this.currentEvent.slug.match(/papaya|zero/i)) {
        value = 1;
      }
      if (this.currentEvent.slug.match(/bagatelle|bonbo/i)) {
        value = 5;
      }
      window.ga('send', 'event', 'Event', 'Tickets', this.currentEvent.slug, value);
      this.$rollbar.info(`Ticket link: ${event}`);
    },
    enlargeImage() {
      this.enlargeImg = !this.enlargeImg;
    },
    formatDate(date) {
      return moment(date).format('dddd, DD MMMM YYYY, hh:mma')
    },
  },
  computed: mapState({
    currentEvent: (state) => state.party,
    partiesLoaded: (state) => state.parties_loaded,
    incomingParties: (state) => state.incomingParties,
    currentEventCaption: (state) =>
      state.party.metadata.caption ? state.party.metadata.caption : '',
    currentEventImage: (state) =>
      state.party.metadata.main_image &&
      state.party.metadata.main_image.imgix_url,
    currentEventPlace: (state) =>
      (venueDecoder(state.party.title).title + "").replace("Tulum",''),
    currentEventLocality: (state) => {
      if (state.party.title && state.party.title.match(/Tulum/i)) {
        return 'Tulum';
      }
      if (state.party.title && state.party.title.match(/Cabo/i)) {
        return 'Cabo San Lucas';
      }
      if (state.party.title && state.party.title.match('Cancun')) {
        return 'Cancun';
      }
      if (state.party.title && state.party.title.match(/Oaxaca/i)) {
        return 'Oaxaca de Juarez';
      }
      if (state.party.title && state.party.title.match('Ciudad de M|Mexico City')) {
        return 'Ciudad de Mexico';
      }
      if (state.party.title && state.party.title.match(/Playa del Carmen/i)) {
        return 'Playa del Carmen';
      }
      return 'Tulum';
    },
    currentEventAddress: (state) =>
      state.party.metadata.venue
        ? state.party.metadata.venue.metadata.address
        : '',
    isPastEvent: (state) => {
      if (state.party.metadata && state.party.metadata.date) {
        return (
          moment.utc(state.party.metadata.date).endOf('day') <=
          moment().startOf('day')
        );
      }
    },
    currentEventDateNTime: (state) =>
      state.party.metadata.date &&
      state.party.metadata.date +
      'T' +
      state.party.metadata.start_time +
      '-05:00',
    cleanContent: (state) =>
      state.party.content &&
      state.party.content.replace(/(<([^>]+)>)/gi, '').substr(0, 200) +
      '&hellip;',
  }),
};
</script>

